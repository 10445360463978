import styled from 'styled-components'
import { variant } from 'styled-system'
import { devices } from 'src/theme/device'
import theme from 'src/theme'

const Timetag = styled.span`
  ${devices.laptop} {
    display: none;
  }
  padding: 2px 7px;
  margin: 3px 3px 0 3px;
  text-transform: lowercase;
  font-size: 11px;
  border-radius: 10px;
  height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${variant({
    variants: {
      primary: {
        color: theme.colors.textGrey,
        border: `1px ${theme.colors.washedOutBlack} solid`,
      },
      new: {
        py: '4px',
        px: 1,
        ml: 1,
        fontSize: 10,
        bg: '#d9ddfc',
        borderRadius: 10,
        color: '#4256ef',
        border: `1px ${theme.colors.washedOutBlack} solid`,
      },
    },
  })};
`

export default Timetag
