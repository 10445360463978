
export function mergeOffers(existingJobPostings: Offer[], newJobPostings: Offer[]): Offer[] {
    const uniqueJobPostings = [...existingJobPostings];
  
    for (const newJobPosting of newJobPostings) {
      const isJobPostingNotPresent = !uniqueJobPostings.some(posting => posting.id === newJobPosting.id);
  
      if (isJobPostingNotPresent) {
        uniqueJobPostings.push(newJobPosting);
      }
    }
    uniqueJobPostings.sort((a, b) => b.createdAt - a.createdAt)
    return uniqueJobPostings;
  }
  