import { Joi } from 'celebrate'

const schema = Joi.object({
  name: Joi.string().required().messages({
    'any.required': 'Пожалуйста введите своё имя',
    'string.empty': 'Пожалуйста введите своё имя',
  }),
  email: Joi.string()
    .email({ minDomainSegments: 2, tlds: { deny: [] } })
    .required()
    .messages({
      'string.empty':
        'Пожалуйста, введите свой адрес электронной почты (e-mail).',
      'any.required':
        'Пожалуйста, введите свой адрес электронной почты (e-mail).',
      'string.email':
        'Пожалуйста, введите действительный адрес электронной почты',
    }),
  cv: Joi.string().required().messages({
    'string.empty': 'No empty',
    'any.required': 'No empty',
  }),
  about: Joi.string().messages(),
})
export const validate = (data) => schema.validate(data, { abortEarly: false })
