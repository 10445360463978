import dynamic from 'next/dynamic'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { Flex } from 'src/styled'
import { SEO } from 'src/components/common'
import { TopNavbar, Offers } from 'src/modules/main/components'
import Offer from 'src/modules/main/components/Offer'
import { devices } from 'src/theme/device'
import { getUserCity } from 'src/utils/cities'
import { useEffect } from 'react'
import { setUserCity } from 'src/redux/reducers/user'

const Map = dynamic(() => import('src/components/common/MapContainer'), {
  ssr: false,
})

const MapContainer = styled(Flex)`
  ${devices.laptop} {
    display: ${({ isDisplayMap }) => !isDisplayMap && 'none'};
  }
`

const OffersContainer = styled(Flex)`
  ${devices.laptop} {
    display: ${({ isDisplayMap }) => isDisplayMap && 'none'};
    max-width: 100vw;
  }
`

function Main() {
  const { isMapVisible } = useSelector((state) => state.offers)
  const dispatch = useDispatch()
  const { query } = useRouter()

  useEffect(() => {
    // getUserCity()
    //   .then((city) => {
    //     dispatch(setUserCity(city))
    //   })
    //   .catch((err) => {
    //     console.log(err)
    //   })
  }, [])

  return (
    <Flex flex={1} flexDirection="column">
      <SEO
        title={'Главная страница Бакор'}
        description="Найдите предложения работы в Центральной Азии (Узбекистан, Таджикистан) и многих других странах."
      />

      <TopNavbar />

      <Flex flex={1} bg="water" zIndex={0}>
        <OffersContainer
          flex={6}
          position={'relative'}
          zIndex={1}
          isDisplayMap={isMapVisible}
        >
          {query.id ? <Offer /> : <Offers />}
        </OffersContainer>

        <MapContainer flex={5} isDisplayMap={isMapVisible}>
          <Map />
        </MapContainer>
      </Flex>
    </Flex>
  )
}

export default Main
