// @ts-check
import { ref, getStorage, uploadString, getDownloadURL } from 'firebase/storage'
import fetcher from 'src/utils/fetcher'

export async function postApplication(offerId, application) {
  try {
    return await fetcher(`/application/${offerId}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(application),
    })
  } catch (error) {
    throw error
  }
}

export function postCV(base64, name) {
  return new Promise(async (resolve, reject) => {
    try {
      name = name.trim().replace(/\s/g, '-')

      const reference = ref(getStorage(), `applicationsCV/${name}`)
      await uploadString(reference, base64, 'data_url', {
        contentType: 'application/pdf',
      })

      const downloadURL = await getDownloadURL(reference)
      resolve(downloadURL)
    } catch (error) {
      reject(error)
    }
  })
}
