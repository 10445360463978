// validate url
export default function isValidURL(url) {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '([a-z\\d]([a-z\\d-]*[a-z\\d])*)' + // domain name
      '(\\.([a-z]{2,}))+' + // dot and top-level domain (TLD)
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  )
  return regex.test(url)
}
