import styled from 'styled-components'
import { devices } from 'src/theme/device'
import { Flex, Text, Grid } from 'src/styled'
import { LazyImage } from 'src/components/common'
import theme from 'src/theme'

export const CardWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 7px;
  padding: 10px 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.15s ease-in;
  background: ${({ leftBorder }) =>
    `linear-gradient(90deg, ${leftBorder} .6%, white .6%)`};
  box-shadow: 5px 5px 5px -5px rgba(0, 0, 0, 0.3);
  &:hover {
    box-shadow: 10px 10px 10px -10px rgba(0, 0, 0, 0.3);
    transform: translateY(-2px);
  }
  min-height: 75px;
  max-height: 75px;
  overflow: hidden;

  ${devices.tablet} {
    background: ${({ leftBorder }) =>
      `linear-gradient(90deg, ${leftBorder} 1%, white 1%)`};
  }
`

export const ImageWrapper = styled.div`
  height: 40px;
  flex: 0 0 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${devices.tablet} {
    flex: 0 0 75px;
  }
`

export const Logo = styled(LazyImage)`
  display: block;
`

export const TopInfo = styled(Flex)`
  align-items: center;
  flex: 1;
  overflow: hidden;
  ${devices.tablet} {
    flex-direction: column;
    align-items: flex-start;
    display: block;
  }
`

export const StyledText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  margin: 0;
`

export const SalaryText = styled(StyledText)`
  color: ${theme.colors.greeny};
  font-size: 13px;
  ${devices.tablet} {
    font-size: 12px;
  }
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`

export const TagContainer = styled(Flex)`
  flex-wrap: wrap;
  overflow: hidden;
  text-align: center;
  justify-content: flex-end;
  height: 27px;
  width: 70%;
`

export const BottomInfo = styled(Flex)`
  flex-direction: row;
  margin-top: auto;
  justify-content: space-between;
`

export const LocationInfo = styled(Flex)`
  align-items: center;
  text-transform: capitalize;
  color: ${theme.colors.secondary};
  font-size: 13px;
  ${devices.tablet} {
    font-size: 12px;
  }
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
  }
`

export const SalaryContainer = styled(Flex)`
  margin-left: auto;
  align-items: center;
  ${devices.tablet} {
    margin-left: 0;
  }
`
export const CardInfo = styled(Grid)`
  padding-right: 10px;
  width: 100%;
  ${devices.tablet} {
    padding: 3px 5px;
  }
`
