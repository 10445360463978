import React, { memo } from 'react'
import styled from 'styled-components'
import { Flex, Button, Box, Text, Span } from 'src/styled'
import { faTimes, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import theme from 'src/theme'
import { devices } from 'src/theme/device'
import { INITIAL_CITY_STATE } from 'src/redux/reducers/offers'
import { getCityTrans } from 'src/constant/cities'

const Modal = styled(Flex)`
  flex-wrap: wrap;
  top: calc(100% + 5px);
  left: 0;
  padding: 10px;
  display: none;
  width: 400px;
  position: absolute;
  background: white;
  z-index: 3;
  border-radius: 5px;
`

const LocationButton = styled(Button)`
  border: 1px solid rgb(228, 232, 240);
  font-size: 14px;
  background: white;
  margin-right: 5px;
  padding: 5px 10px;
  padding-right: 50px;
  height: 32px;
  width: fit-content;
  text-align: left;
  position: relative;
  border-radius: 25px;

  > svg {
    position: absolute;
    right: 10px;
    transition: 0.3s ease-in-out;
  }
`

const CloseButton = styled(Span)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;

  &:hover {
    background: rgba(242, 242, 242, 0.8);
  }
`

export const CityButton = styled(Button)`
  font-size: 14px;
  margin-right: 10px;
  padding: 5px 10px;
  margin-bottom: 0;
`

const Header = styled(Flex)`
  margin-bottom: 8px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const StyledBox = styled(Box)`
  &:focus-within,
  &:active {
    ${Modal} {
      display: block !important;
    }
    ${LocationButton} {
      color: black;
      border: 1px solid ${theme.colors.primary};
      svg {
        transform: rotate(180deg);
        color: ${theme.colors.primary};
      }
    }
  }

  ${devices.laptop} {
    display: none;
  }
`

const Cities = memo(({ currentCity, cities, onSelect, isDisabled }) => {
  function selectCity(city) {
    onSelect(city)
    closeModal()
  }

  const closeModal = () => document.getElementById('locationBtn').blur()
  const onFocus = () => document.getElementById('locationBtn').focus()

  return (
    <StyledBox>
      <LocationButton id="locationBtn" onClick={onFocus}>
        {currentCity === INITIAL_CITY_STATE
          ? 'Везде'
          : getCityTrans('ru', currentCity)}
        <FontAwesomeIcon icon={faChevronDown} size={'1x'} />
      </LocationButton>

      <Modal shadowed>
        <Header>
          <Text fontSize={18} color="darkGray">
            {'Выберите город'}
          </Text>

          <CloseButton onClick={closeModal}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </Header>

        <Flex flexWrap="wrap" mb={3}>
          <CityButton
            as={'span'}
            onClick={() => selectCity('Everywhere')}
            variant={isDisabled('Everywhere') ? 'primary-reverse' : 'disabled'}
          >
            Везде
          </CityButton>
          {cities &&
            cities.map((city, index) => (
              <CityButton
                as={'span'}
                key={index}
                onClick={() => selectCity(city)}
                variant={isDisabled(city) ? 'primary-reverse' : 'disabled'}
              >
                {getCityTrans('ru', city)}
              </CityButton>
            ))}
        </Flex>
      </Modal>
    </StyledBox>
  )
})

export default Cities
