import { getAllCities } from 'src/constant/cities'

interface City {
  english: string
  russian: string
  population: number
  coordinate: [number, number]
}

// Function to calculate the distance between two sets of coordinates using the Haversine formula
export function calculateDistance(
  coord1: [number, number],
  coord2: [number, number],
): number {
  const [lat1, lon1] = coord1
  const [lat2, lon2] = coord2

  const R = 6371 // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180)
  const dLon = (lon2 - lon1) * (Math.PI / 180)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = R * c

  return distance
}

// Function to find the closest city based on coordinates
export function findClosestCity(
  targetCoord: [number, number],
  cities: City[],
): City {
  let closestCity: City = {
    english: 'Tashkent',
    russian: 'Ташкент',
    population: 2352900,
    coordinate: [41.287478, 69.261169],
  }

  let minDistance = Infinity
  cities.forEach((city) => {
    const distance = calculateDistance(targetCoord, city.coordinate)
    if (distance < minDistance) {
      minDistance = distance
      closestCity = city
    }
  })
  return closestCity
}

export async function getUserCity(): Promise<City | null> {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const closestCity = findClosestCity([latitude, longitude], getAllCities() as City[]);
          resolve(closestCity);
        },
        (error) => {
          console.error('Error getting user location:', error.message);
          reject(null); // Resolve with null if there's an error
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      reject(null); // Resolve with null if geolocation is not supported
    }
  });
}
