import { toast } from 'react-toastify'

export const notifySuccess = (message) => toast.success(message)

export const notifyError = (message, error) => {
  toast.error(message)
  if (error) console.error(error)
}

export const notifyInfo = (message) => {
  toast.info(message)
}
