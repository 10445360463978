import { useState } from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'
import { Box, Grid, Button } from 'src/styled'
import { Input, Textarea, UploadPDF, Loading } from 'src/components/common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons'
import useForm from 'src/hooks/useForm'
import { validate } from './validation'
import { postApplication, postCV } from './api'
import { notifySuccess, notifyError } from 'src/notification'
import { devices } from 'src/theme/device'
import isValidURL from 'src/utils/isValidURL'

const StyledGrid = styled(Grid)`
  grid-template-columns: repeat(2, 50%);
  grid-gap: 0 10px;
  ${devices.tablet} {
    grid-template-columns: 1fr;
  }
`

const StyledButton = styled(Button)`
  margin-top: 30px;
  padding: 10px 30px;
`

const initState = {
  name: '',
  email: '',
  about: '',
  cv: '',
  fileName: '',
}

function ApplicationForm() {
  const { state, handleChange, setState } = useForm(initState)
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()
  const { id: offerID } = router.query

  function onUploadPDf(fileName, base64) {
    setState((prevState) => ({ ...prevState, cv: base64, fileName }))
  }

  function validateForm(application) {
    const result = validate(application)
    if (result.error) {
      const { details } = result.error
      const newErrors = {}
      details.forEach((error) => (newErrors[error.path[0]] = error.message))
      setErrors(newErrors)
      return false
    }
    setErrors({})
    return true
  }

  async function onApply() {
    const application = {
      cv: state.cv,
      name: state.name,
      email: state.email,
      about: state.about,
    }

    if (!validateForm(application)) {
      notifyError('Пожалуйста, заполните все пустые поля ввода')
      return
    }
    setIsLoading(true)

    try {
      //Get the base64 and upload to store and replace it with URL
      if (!isValidURL(application.cv)) {
        const cvURL = await postCV(application.cv, state.fileName)
        application.cv = cvURL
      }

      await postApplication(offerID, application)
      notifySuccess('Ваша заявка успешно отправлена ​​работодателю')
      setState(initState)
    } catch (error) {
      notifyError(
        'Ошибка при отправке формы заявки, пожалуйста попробуйте заново',
        error,
      )
    }

    setIsLoading(false)
  }

  return (
    <Box>
      <StyledGrid>
        <Input
          label={'Имя и Фамилия'}
          name="name"
          onChange={handleChange}
          value={state.name}
          error={errors.name}
        />

        <Input
          value={state.email}
          label={'E-mail'}
          name="email"
          error={errors.email}
          onChange={handleChange}
        />

        <UploadPDF
          label={
            state.fileName ? state.fileName : 'Загрузить резюме (только pdf)'
          }
          onUpload={(name, value) => onUploadPDf(name, value)}
        />

        <Textarea
          label={'Опишите себя'}
          name="about"
          value={state.about}
          onChange={handleChange}
          error={errors.about}
        />
      </StyledGrid>

      <StyledButton variant={'primary'} onClick={onApply}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {'Подать заявку'}{' '}
            <FontAwesomeIcon icon={faTelegramPlane} size="lg" />{' '}
          </>
        )}
      </StyledButton>
    </Box>
  )
}
export default ApplicationForm
