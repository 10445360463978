import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding, faMapMarked } from '@fortawesome/free-solid-svg-icons'

import Tag from './Tag'
import { Span } from 'src/styled'
import {
  CardWrapper,
  Logo,
  ImageWrapper,
  SalaryText,
  BottomInfo,
  LocationInfo,
  StyledText,
  TagContainer,
  TopInfo,
  SalaryContainer,
  CardInfo,
} from './styled'
import jobCategories from 'src/constant/jobCategories'
import { getCityTrans } from 'src/constant/cities'
import { getDayOfPublication } from 'src/utils/getTimesPassed'

export default function Card({ offerData, onCardClickHandler }) {
  const {
    title,
    minSalary,
    maxSalary,
    companyName,
    currency,
    createdAt,
    category,
    neccessarySkills,
    city,
    companyLogo,
    id,
  } = offerData

  const { color } = jobCategories[category]
  const MAX_SKILLS_PREVIEW = 3

  const getSlicedSkills = (skills) => skills.slice(0, MAX_SKILLS_PREVIEW)

  const dateOfPublication = getDayOfPublication(createdAt, 'ru')
  return (
    <CardWrapper id={id} leftBorder={color} onClick={onCardClickHandler}>
      <ImageWrapper>
        <Logo
          src={companyLogo}
          style={{
            width: '45%',
            height: 'auto',
          }}
          width={60}
          height={60}
          alt={`offer-${title}`}
        />
      </ImageWrapper>

      <CardInfo>
        <TopInfo>
          <StyledText fontSize={[14, 16]}>{title}</StyledText>

          <SalaryContainer>
            {!!minSalary && (
              <SalaryText>
                {minSalary === maxSalary
                  ? `${minSalary}`
                  : `${minSalary} - ${maxSalary}`}
                &nbsp;{currency}
              </SalaryText>
            )}

            <Tag
              variant={
                (dateOfPublication === 'New') |
                (dateOfPublication === 'Новинка')
                  ? 'new'
                  : 'primary'
              }
            >
              &nbsp;{dateOfPublication}
            </Tag>
          </SalaryContainer>
        </TopInfo>

        <BottomInfo>
          <LocationInfo>
            <Span>
              <FontAwesomeIcon icon={faBuilding} size={'sm'} /> {companyName}
            </Span>

            <Span ml={3}>
              <FontAwesomeIcon icon={faMapMarked} size={'sm'} />{' '}
              {getCityTrans('ru', city)}
            </Span>
          </LocationInfo>

          <TagContainer>
            {getSlicedSkills(neccessarySkills).map((skill, index) => (
              <Tag key={index} variant="primary" color="darkGray">
                {skill.name}
              </Tag>
            ))}
          </TagContainer>
        </BottomInfo>
      </CardInfo>
    </CardWrapper>
  )
}
