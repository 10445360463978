import { useCallback, useState } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Flex, Box, Button, Text } from 'src/styled'
import { IndustryCategory, Modal } from 'src/components/common'

import CATEGORIES_LIST from 'src/constant/jobCategories'
import { getUniqueCities, getUniqueCategories } from './TopNavHelper'
import { industries } from 'src/constant/industries'
import Cities from './Cities'
import {
  INITIAL_CATEGORY_STATE,
  INITIAL_CITY_STATE,
  setOffersCategory,
  setOffersCity,
  setMapVisibility,
} from 'src/redux/reducers/offers'
import { devices } from 'src/theme/device'
import theme from 'src/theme'
import { CityButton } from './Cities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { getCityTrans } from 'src/constant/cities'

const Container = styled(Flex)`
  position: relative;
  align-items: center;
  height: fit-content;
  padding: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100vw;
  background: white;
  z-index: 1;
  > * {
    margin: 0 10px 5px 0;
  }
  height: fit-content;
`

const StyledBox = styled(Box)`
  overflow: auto;
  display: flex;
  align-items: center;
  padding: 10px;
  > * {
    margin-right: 10px;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  ${devices.laptop} {
    display: none;
  }
`

const FilterButton = styled(Button)`
  display: none;
  margin: 0 5px 0 0;
  padding: 5x 10px !important;
  height: 32px;
  ${devices.laptop} {
    display: initial;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`
const MapDisplayButton = styled(Button)`
  display: none;
  margin: 0 5px 0 0;
  padding: 5x 10px !important;
  height: 32px;
  ${devices.laptop} {
    display: initial;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
  background: #fdeaf0;
`

const FlexWrapper = styled(Flex)`
  flex-wrap: wrap;
  > * {
    margin: 0 10px 10px 0;
  }
`

export default function Topnav() {
  const [categoryModalActive, setCategoryModalActive] = useState(false)
  const [locationModalActive, setLocationModalActive] = useState(false)
  const dispatch = useDispatch()
  const { allOffers, filters } = useSelector((state) => state.offers)
  const { category: activeCategory, city: activeCity } = filters
  const cities = getUniqueCities(allOffers)
  const categories = Object.values(industries)
  const onSelectCategory = useCallback((category) => {
    dispatch(setOffersCategory(category))
  })

  const isDisabledCategory = useCallback(
    (category) =>
      activeCategory === INITIAL_CATEGORY_STATE || activeCategory === category,
  )

  const onSelectCity = useCallback((city) => dispatch(setOffersCity(city)))

  const isDisabledCity = useCallback(
    (city) => activeCity === INITIAL_CITY_STATE || activeCity === city,
  )
  const onDisplayMap = useCallback((isMapVisible) =>
    dispatch(setMapVisibility(isMapVisible)),
  )

  return (
    <>
      <Container>
        <Cities
          currentCity={activeCity}
          cities={cities}
          isDisabled={isDisabledCity}
          onSelect={onSelectCity}
        />

        <FilterButton
          variant={'raw'}
          onClick={() => setLocationModalActive(true)}
        >
          {'Pасположение'}
        </FilterButton>

        <FilterButton
          variant={'raw'}
          onClick={() => setCategoryModalActive(true)}
        >
          {'Kатегория'}
        </FilterButton>

        <MapDisplayButton variant={'raw'} onClick={() => onDisplayMap(true)}>
          <FontAwesomeIcon color={theme.colors.primary} icon={faMapMarkedAlt} />
        </MapDisplayButton>

        <StyledBox>
          {categories &&
            categories.map((category, index) => (
              <IndustryCategory
                key={index}
                category={CATEGORIES_LIST[category]}
                iconClickHandler={onSelectCategory}
                disabled={!isDisabledCategory(category)}
                iconPosition={'TopNav'}
              />
            ))}
        </StyledBox>
      </Container>

      {locationModalActive && (
        <Modal
          title={'Фильтровать город'}
          onClose={() => setLocationModalActive(false)}
        >
          <Text mb={2}> {'Города'}</Text>
          <Flex mb={4} flexWrap="wrap">
            {cities &&
              cities.map((city, index) => (
                <Box as="span" onClick={() => setLocationModalActive(false)}>
                  <CityButton
                    key={index}
                    onClick={() => onSelectCity(city)}
                    variant={
                      isDisabledCity(city) ? 'primary-reverse' : 'disabled'
                    }
                  >
                    {getCityTrans('ru', city)}
                  </CityButton>
                </Box>
              ))}
          </Flex>
        </Modal>
      )}

      {categoryModalActive && (
        <Modal
          title={'Фильтровать категорию'}
          onClose={() => setCategoryModalActive(false)}
        >
          <Text mb={2}>{'Kатегория'}</Text>
          <FlexWrapper>
            {categories &&
              categories.map((category, index) => (
                <Box as="span" onClick={() => setCategoryModalActive(false)}>
                  <IndustryCategory
                    key={index}
                    category={CATEGORIES_LIST[category]}
                    iconClickHandler={onSelectCategory}
                    disabled={!isDisabledCategory(category)}
                    iconPosition={'TopNav'}
                  />
                </Box>
              ))}
          </FlexWrapper>
        </Modal>
      )}
    </>
  )
}
