// @ts-check
import Card from './Card'
import { Flex } from 'src/styled'
import styled from 'styled-components'
import { devices } from 'src/theme/device'
import { useDispatch, useSelector } from 'react-redux'
import NoSearchResultImage from 'src/assets/img/no-search-result.svg'
import Router from 'next/router'
import { useEffect, useState } from 'react'
import { PAGINATION_SIZE, setOffers } from 'src/redux/reducers/offers'
import fetcher from 'src/utils/fetcher'
import { Loading } from 'src/components/common'
import Image from 'next/image'
import { mergeOffers } from 'src/utils/mergeOffers'
const ListWrapper = styled(Flex)`
  ${devices.laptop} {
    position: relative;
    width: 100%;
    height: fit-content;
  }
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 10px 10px 10px 10px;
`

export default function Offers() {
  const [isLoading, setIsLoading] = useState(true)
  const { allOffers, filteredOffers, filters } = useSelector(
    (state) => state.offers,
  )
  const { category, city } = filters
  const [exhaustedCombination, setExhaustedCombination] = useState(new Set())
  // Set of city-category pair which tracks all the offers fethched for that category

  const dispatch = useDispatch()
  const handleRedirect = (id) => Router.push(`/?id=${id}`)
  const getKey = (category, city) => `${category}-${city}`

  function setObserverForMoreOffers() {
    // If we have offers, attach an IntersectionObserver to the last item so we can load more when observed.
    if (filteredOffers.length == 0) return

    const lastOffer = document.getElementById(
      filteredOffers[filteredOffers.length - 1].id,
    )
    const observer = new IntersectionObserver(async ([entry]) => {
      if (!entry.isIntersecting) return
      observer.unobserve(lastOffer)
      fetchOffers(category, city)
    })
    observer.observe(lastOffer)
  }

  async function fetchOffers(category, city) {
    const key = getKey(category, city)
    if (exhaustedCombination.has(key)) {
      console.log('Already fetched all offers:', key)
      return
    }

    setIsLoading(true)
    try {
      const params = {
        paginationStartIndex: filteredOffers.length,
        paginationSize: PAGINATION_SIZE,
        category: category,
        city: city,
      }
      const url =
        '/offers?' +
        Object.entries(params)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      const newOffers = await fetcher(url)
      if (newOffers.length > 0) {
        dispatch(setOffers(mergeOffers(allOffers, newOffers)))
      } else {
        console.log(
          'No more offers left for this combination:',
          key,
          'marking it as exausted',
        )
        exhaustedCombination.add(key)
      }
    } catch (error) {
      console.error('error fetching offers', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchOffers(category, city)
  }, [category, city])

  useEffect(() => {
    setObserverForMoreOffers()
  }, [filteredOffers])
  return (
    <>
      {filteredOffers && filteredOffers.length > 0 ? (
        <ListWrapper id="offers-wrapper">
          {filteredOffers.map((offer) => (
            <Card
              id={offer.id}
              key={offer.id}
              offerData={offer}
              onCardClickHandler={() => handleRedirect(offer.id)}
            />
          ))}
          {isLoading && (
            <div>
              <Loading color={'#ff4081'} />
            </div>
          )}
        </ListWrapper>
      ) : (
        <>
          {isLoading ? (
            <Flex flex={1}>
              <Loading color={'#ff4081'} />
            </Flex>
          ) : (
            <Flex
              flexDirection="column"
              alignItems="center"
              width="100%"
              mt={50}
            >
              <Image src={NoSearchResultImage} height={300} width={300} />
              <h4>Предложений нет, пожалуйста, зайдите позже</h4>
            </Flex>
          )}
        </>
      )}
    </>
  )
}
