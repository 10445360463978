//@ts-check
import { faPython, faSellsy } from '@fortawesome/free-brands-svg-icons'
import {
  faGlobeAmericas,
  faLandmark,
  faCashRegister,
  faUserGraduate,
  faMailBulk,
  faTasks,
  faCalculator,
  faAd,
  faUsers,
  faChartLine,
  faStethoscope,
  faAddressCard,
} from '@fortawesome/free-solid-svg-icons'
import { industries } from './industries'

export default {
  All: {
    name: 'All',
    color: '#FF7E00',
    borderColor: '#FFC966',
    icon: faGlobeAmericas,
  },

  // [industries.Administation]: {
  //   name: industries.Administation,
  //   color: '#E5E500',
  //   borderColor: '#FFFF99',
  //   icon: faAddressCard,
  // },

  [industries.Finance]: {
    name: industries.Finance,
    color: '#009900',
    borderColor: '#66FF66',
    icon: faLandmark,
  },

  [industries.SalesAndMarketing]: {
    name: industries.SalesAndMarketing,
    color: '#0000D6',
    borderColor: '#6699FF',
    icon: faSellsy,
  },

  [industries.Commerce]: {
    name: industries.Commerce,
    color: '#660066',
    borderColor: '#B366B3',
    icon: faCashRegister,
  },

  [industries.Analytics]: {
    name: industries.Analytics,
    color: '#D60094',
    borderColor: '#FF6FAB',
    icon: faChartLine,
  },

  [industries.Education]: {
    name: industries.Education,
    color: '#006666',
    borderColor: '#66B3B3',
    icon: faUserGraduate,
  },

  [industries.Management]: {
    name: industries.Management,
    color: 'rgb(241, 70, 76)',
    borderColor: 'rgb(248, 100, 104)',
    icon: faTasks,
  },

  [industries.Accounting]: {
    name: industries.Accounting,
    color: 'rgb(103, 37, 114)',
    borderColor: '#8a329a',
    icon: faCalculator,
  },

  [industries.Technology]: {
    name: industries.Technology,
    color: '#0074D9',
    borderColor: '#6FB9FF',
    icon: faPython,
  },

  [industries.HR]: {
    name: industries.HR,
    color: '#742da7',
    borderColor: '#8e57b5',
    icon: faUsers,
  },

  [industries.Healthcare]: {
    name: industries.Healthcare,
    color: 'rgb(255, 175, 0)',
    borderColor: 'rgb(255, 199, 6)',
    icon: faStethoscope,
  },

  [industries.Other]: {
    name: industries.Other,
    color: '#01b77b',
    borderColor: '#44e4af',
    icon: faUsers,
  },

  [industries.CustomerService]: {
    name: industries.CustomerService,
    color: '#157cfc',
    borderColor: '#41adfa',
    icon: faCalculator,
  },
}
