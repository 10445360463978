import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { devices } from 'src/theme/device'
import parse from 'html-react-parser'
import { useRouter } from 'next/router'

import { Flex, Box, Text } from 'src/styled'
import {
  OfferPreview,
  RatingSelection,
  SEO,
  Loading,
} from 'src/components/common'
import ApplicationForm from './Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import fetcher from 'src/utils/fetcher'
import { setMapCoordinates } from 'src/redux/reducers/offers'

const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.water};
  height: 3px;
  border: 0;
  margin: 10px 0;
`

const Description = styled(Box)`
  font-size: 14px;
  font-weight: 340;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #34444b;
  li {
    margin-left: 40px;
  }
`

const OfferContainer = styled(Box)`
  ${devices.laptop} {
    position: relative;
    width: 100%;
    height: fit-content;
  }
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
`

const Segment = ({ title, children }) => (
  <Box shadowed width={1} bg={'white'} borderRadius={5} mt={40} padding={20}>
    <Text fontSize={{ _: 12, lg: 18 }} color={'secondary'}>
      {title}
    </Text>
    <Divider />
    <Description>{children}</Description>
  </Box>
)

const ListWrapper = styled(Flex)`
  ${devices.laptop} {
    position: relative;
    width: 100%;
    height: fit-content;
  }
  padding: 0 20px 20px 20px;
  flex-direction: column;
`

const BackButton = styled(Box)`
  position: absolute;
  top: 10px;
  left: 30px;
  height: 30px;
  width: 30px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`

function Offer() {
  const [isLoading, setIsLoading] = useState(true)
  const [offer, setOffer] = useState(undefined)
  const { query, push } = useRouter()
  const dispatch = useDispatch()
  useEffect(() => {
    const fetchOffer = async () => {
      try {
        setIsLoading(true)

        const newOffer = await fetcher(`/offers/${query.id}`)
        setOffer(newOffer)
        dispatch(setMapCoordinates(newOffer.coordinates))
        setIsLoading(false)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOffer()
  }, [query])

  if (isLoading) {
    return <Loading />
  }

  if (!offer) return <h1>Предложение не найдено</h1>

  const { description, companyLogo, neccessarySkills, niceToHaveSkills } = offer
  const goHome = () => push('/')

  return (
    <OfferContainer>
      <SEO
        title={`${offer.title}-${offer.companyName}`}
        description={`Вакансии-${offer.title} от ${offer.companyName} в городе ${offer.city}`}
      />

      <ListWrapper>
        <BackButton onClick={goHome}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </BackButton>

        <OfferPreview brandIcon={companyLogo} offer={offer} />

        {(neccessarySkills.length > 0 || niceToHaveSkills.length > 0) && (
          <Segment title={'Навыки'}>
            <RatingSelection
              ratingList={[...neccessarySkills, ...niceToHaveSkills]}
            />
          </Segment>
        )}

        <Segment title={'Описание'}>{parse(description)}</Segment>

        <Segment title={'Подать заявку на данную позицию'}>
          <ApplicationForm />
        </Segment>
      </ListWrapper>
    </OfferContainer>
  )
}
export default Offer
